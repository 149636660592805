import React, { useEffect, useRef } from 'react';
import {Typography, Grid, Container, Box} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { navigate } from 'gatsby';
import useStyles from './styles';

const HowWeCanHelpComponent = ({language, pageContext}) => {

    const classes = useStyles();

    const isInitialMount = useRef(true);

    useEffect(() => {   
      if (isInitialMount.current) {
        isInitialMount.current = false;
     } else {
      if(language.english){
        navigate(`/how-we-can-help`)
       }else{
        navigate(`/cabhair-a-thugaimid`)
       }
     }
    },[language.english]);

    const theme = useTheme();
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    const image1 = pageContext.contactCard[0].image.localFile.childImageSharp;
    const image2 = pageContext.contactCard[1].image.localFile.childImageSharp;

      
    return(
        <Box role="main" aria-label="how we can help">
        <Container maxWidth="xl">
        <Grid container>
            <Grid item xs={12} mb={6}>
                <Container maxWidth="md">
                <Grid item xs={12} mt={6} mb={2}>
                    <Typography variant="heading" component="h1" style={{fontSize: matchesSm ? '40px' : undefined, lineHeight: matchesSm ? '48px': undefined}} gutterBottom>{language.english ? pageContext.title : pageContext.titleIrish}</Typography>
                </Grid>
                <Typography variant="primary3" className={classes.innerHtml} dangerouslySetInnerHTML={{__html:  language.english ? pageContext.content : pageContext.contentIrish}} />
                </Container>
            </Grid>
        </Grid>
    </Container>
    <Grid container style={{backgroundColor:'#E6EDEC'}}>
        <Container maxWidth="xl">

            <Grid container display="flex" mt={5} mb={5}>
                <Grid item sm={12} lg={4} p={3}>
                    <Typography variant="bodyheading" component="h2">{language.english ? 'Contact Us' : 'Glaoigh orainn'}</Typography>
                    
                    <Typography variant="bodysmall" dangerouslySetInnerHTML={{__html:  language.english ? pageContext.contactus : pageContext.contactusIrish}} />
                </Grid>
                <Grid item sm={12} md={6} lg={4} p={3} display="flex" justifyContent={matchesLg ? undefined : "center" }>
                    <Box style={{backgroundColor:'white', borderRadius:15, width:matchesSm ? '100%' : '80%'}} p={3}>
                        <Box mt={2} style={{height:'140px'}}>
                       
                        <GatsbyImage style={{borderRadius: '50%', width:'125px', height:'125px'}} image={getImage(image1)} alt={language.english ? pageContext.contactCard[0].image.altText : pageContext.contactCard[0].image.altTextIrish.irishAlt} />
                        </Box>
                        <span lang="ga">
                            <Typography variant="cardheading" gutterBottom display={"block"}>{pageContext.contactCard[0].name}</Typography>
                        </span>
                        <Typography variant="bodysmall" display={"block"}>
                        {language.english ? pageContext.contactCard[0].text : pageContext.contactCard[0].textIrish}
                        </Typography>
                        <Typography variant="bodysmall" gutterBottom display={"block"}>
                            <a className={classes.innerA} id="main" href={`mailto:${pageContext.contactCard[0].email}`}>{pageContext.contactCard[0].email}</a>
                        </Typography>
                        <Typography variant="bodysmall" display={"block"}>
                            <a className={classes.innerA} href={`tel:${pageContext.contactCard[0].phone}`}>{pageContext.contactCard[0].phone}</a>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12} md={6} lg={4} p={3}>
                    <Box style={{backgroundColor:'white', borderRadius:15, width:matchesSm ? '100%' : '80%'}} p={3}>
                        <Box mt={2} style={{height:'140px'}}>
                            <GatsbyImage style={{borderRadius: '50%', width:'125px', height:'125px'}} image={getImage(image2)} alt={language.english ? pageContext.contactCard[1].image.altText : pageContext.contactCard[1].image.altTextIrish.irishAlt} />
                        </Box>
                        
                        <span lang="ga">
                            <Typography variant="cardheading" gutterBottom display={"block"}>{pageContext.contactCard[1].name}</Typography>
                        </span>

                        <Typography variant="bodysmall" display={"block"}>
                        {language.english ? pageContext.contactCard[1].text : pageContext.contactCard[1].textIrish}
                        </Typography>
                        <Typography variant="bodysmall" gutterBottom display={"block"}>
                        <a className={classes.innerA} href={`mailto:${pageContext.contactCard[1].email}`}>{pageContext.contactCard[1].email}</a>
                        </Typography>
                        <Typography variant="bodysmall" display={"block"}>
                        <a className={classes.innerA} href={`tel:${pageContext.contactCard[1].phone}`}>{pageContext.contactCard[1].phone}</a>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Grid>
    </Box>
    )
}


    

export default HowWeCanHelpComponent;
