import React, {useState, useEffect} from 'react';
import Layout from '../components/layout';
import LanguageContext from "../context/LanguageContext";
import HowWeCanHelp from '../components/HowWeCanHelp/HowWeCanHelpComponent';
import Seo from '../components/seo';
import CircularProgress from '@mui/material/CircularProgress';

const HowWeCanHelpPage = ({pageContext}) => {

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
      setHasMounted(true);
  }, []);

  if(!hasMounted) return <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}><CircularProgress /></div>;

    return (
    <LanguageContext.Consumer>
        {language => (
    <Layout>
       <Seo title="How we can help" lang={language.english ? "en" : 'ga'} />
       <HowWeCanHelp language={language} pageContext={pageContext} />
    </Layout>
    )}
    </LanguageContext.Consumer>
  );
}

export default HowWeCanHelpPage;